import { Component, OnInit } from '@angular/core';
import * as Constants from '../../assets/constants/Constants';

@Component({
  selector: 'app-representatives',
  templateUrl: './representatives.component.html',
  styleUrls: ['./representatives.component.css']
})
export class RepresentativesComponent implements OnInit {
  C = Constants;

  constructor() { }

  ngOnInit(): void {
  }

}
