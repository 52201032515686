import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { OnlineAppointmentComponent } from './online-appointment/online-appointment.component';
import { MedicalOfficeComponent } from './medical-office/medical-office.component';
import { RepresentativesComponent } from './representatives/representatives.component';
import { LinksComponent } from './links/links.component';

const routes: Routes =[
  { path: '',                     redirectTo: 'home', pathMatch: 'full'},
  { path: 'home',                 component: HomeComponent },
  { path: 'online-afspraak',      component: OnlineAppointmentComponent },
  { path: 'praktijk',             component: MedicalOfficeComponent },
  { path: 'vertegenwoordigers',   component: RepresentativesComponent },
  { path: 'links',                component: LinksComponent },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', useHash: true})
  ],
  exports: [
    RouterModule
  ],
})
export class AppRoutingModule { }
