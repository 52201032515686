import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  @Input() office: string;
  @Input() doctors?: string;
  @Input() address: string;
  @Input() telnr: string;
  @Input() faxnr?: string;
  @Input() link?: string;

  constructor() { }

  ngOnInit(): void {
  }

}
