import { Component, OnInit } from '@angular/core';
import * as Constants from '../../assets/constants/Constants';


@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.css']
})
export class LinksComponent implements OnInit {
  C = Constants;
  gridByBreakpoint = {
    xl: 8,
    lg: 6,
    md: 4,
    sm: 2,
    xs: 1
  }
  links = [
    { "title": "Info Coronavirus", "url": "https://www.info-coronavirus.be/nl/?utm_source=beamer&utm_medium=sidebar&utm_campaign=COVID-19---Hou-uw-patinten-op-de-hoogte&utm_content=textlink"}, 
    { "title": "Apotheker van wacht", "url": "https://www.apotheek.be/"}, 
    { "title": "Child Focus", "url": "http://www.childfocus.be/"}, 
    { "title": "Sportmedisch Onderzoek", "url": "https://www.sportkeuring.be/"}, 
    { "title": "Belgisch Antigifcentrum", "url": "https://www.antigifcentrum.be/"}, 
    { "title": "Zelfmoordlijn", "url": "https://www.preventiezelfdoding.be/"}, 
    { "title": "Tele-onthaal", "url": "https://www.tele-onthaal.be/"}, 
    { "title": "Anonieme Alcoholisten", "url": "https://www.aavlaanderen.org/"}, 
    { "title": "Agentschap Zorg & Gezondheid", "url": "https://www.zorg-en-gezondheid.be/"}, 
    { "title": "RZ Heilig-Hart Leuven ", "url": "https://www.hhleuven.be/nl/"}, 
    { "title": "RZ Tienen", "url": "https://www.rztienen.be/nl/"}, 
    { "title": "UZ Gasthuisberg", "url": "https://www.uzleuven.be/nl"}, 
    { "title": "MCH Leuven", "url": "https://www.medischcentrumhuisartsen.be/documents/home.xml?lang=nl"}, 
    { "title": "Kind en Gezin", "url": "https://www.kindengezin.be/"}, 
    { "title": "Diabetes Liga", "url": "https://www.diabetes.be/"}, 
    { "title": "Instituut voor Tropische Geneeskunde", "url": "https://www.itg.be/"}, 
    { "title": "Sensoa", "url": "https://www.sensoa.be/"}, 
    { "title": "Stoppen met roken", "url": "https://www.tabakstop.be/hoe-stoppen-met-roken"}, 
  ]

  constructor() { }

  ngOnInit(): void {}
  
}
