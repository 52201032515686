//General and navigation
export const Title = 'Dokterspraktijk Vissenaken';
export const TitleNav = `Dokterspraktijk<br>Vissenaken`;
export const Home = 'Home';
export const MakeAppointment = 'Online Afspraak';
export const Doctors = 'Arts';
export const Representatives = 'Vertegenwoordigers';
export const Office = 'De Praktijk';
export const WatchDuty = 'Wachtdienst';
export const Links = 'Links';
export const ContactInformation = 'Contact Informatie';
export const SiteCreatorName = 'GBIT'
export const SiteCreatorEmail = 'gill@gbit-consultancy.be'

//Doctors
export const DoctorName = 'Dr. Patrick Vanbelle';
export const DoctorTitle = 'Erkend, geaccrediteerd, gedeconventioneerd huisarts';
export const DoctorDescription = 
    `   
        ${DoctorName} is huisarts en huisarts-opleider. 
        Zijn speciale interesses zijn orthopedische sportletsels, kleine heelkunde en pediatrie.
    `;

// export const HaioName = '';
// export const HaioTitle = 'Erkend, geaccrediteerd, geconventioneerd huisarts';
// export const HaioDescription = 
//     `
//         ${HaioName} zal onze ploeg het komende jaar versterken als HAIO (’huisarts-in-opleiding’), 
//         waarbij de 7-jarige basisopleiding tot basisarts al is afgerond, maar er nog een periode van supervisie in onze opleidingspraktijk volgt, 
//         om zijn bekwaamheden verder uit te bouwen. 
//         Zoals iedere huisarts behandelt hij patiënten van alle leeftijden, van zuigeling tot oudere. 
//         Daarenboven heeft hij een bijzondere interesse voor de kindergeneeskunde en de gynaecologie.
//     `;

//Home
export const WelcomeTitle = 'Welkom bij';
export const WelcomeCardTitle = 'Omvorming Groepspraktijk Vissenaken'
export const WelcomeCardDescription = 
    `   
        <p>
            Buiten de gewone huisartsgeneeskundige taken, kan je bij ons ook specifiek terecht voor:
            <ul>
                <li>Corona-neuswissers</li>
                <br>
                <li>Vaccinaties bij baby’s, kinderen en volwassenen</li>
                <br>
                <li>Pediatrie</li>
                <br>
                <li>Orthopedische sportletsels</li>
            </ul>
        </p>
    `;
export const CovidCardTitle = 'Covid-19';
export const CovidCardDescription = 
    `
        <p>
            Maatregelen ten tijden van het Coronavirus:
            <ul>
                <li>Gelieve bij het boeken uw telefoonnummer en reden van contact te vermelden.</li>
                <li>U hoeft niet te wachten maar mag direct de wachtzaal (met mondmasker) betreden.</li>
            </ul>
            Bij voorbaat dank!
        </p>
    `;
export const AppointmentCardTitle = 'Maak een online afspraak';
export const AppointmentCardDescription = 
    `
        Indien alle consultaties op de website volzet zijn en u wenst toch een dringend medisch consult, 
        kunt u altijd telefonisch terecht bij het telesecretariaat. We zullen u dan in overleg zo snel mogelijk voorthelpen.
    `;

// Contact
export const OfficeVissenaken = 'Huisartsenpraktijk Vissenaken:';
export const DoctorsVissenaken = `${DoctorName}`;
export const TelNrVissenaken = 'Tel. 016 81 92 87';
export const FaxNrVissenaken = 'Fax. 016 82 35 55';
export const AddressVissenaken = 'Vissenakenstraat 541 A, 3300 Vissenaken (Tienen)';

export const OfficeWever = 'Huisartsenpraktijk Wever:';
export const DoctorsWever = 'Dr. Vincent Vanbelle of Dr. Jan Vermeij';
export const OfficeWeverContact = `Voor ${DoctorsWever}, gelieve te bellen naar:`;
export const TelNrWever = 'Tel. 016 79 07 20';
export const AddressWever = 'Torenstraat 20 A, 3384 Attenrode-Wever';
export const LinkWever = 'https://www.huisartsenpraktijkwever.be';

//Online afspraak
export const AppointmentInformationCardTitle = 'Eenvoudig een online afspraak maken';
export const AppointmentInformationCardDescription1 = 
    `
        U kunt met één druk op de knop hieronder online een afspraak maken. Hiervoor hoeft u nog geen login en paswoord te hebben.
        Wanneer u een afspraak gemaakt heeft, bezorgt het systeem u per e-mail automatisch een login en paswoord zodat u de gemaakte afspraak kunt beheren (wijzigen/verwijderen) indien nodig.
        In het afsprakenvenster dat verschijnt kunt u linksboven een datum aanklikken waarop u een afspraak wenst te maken. De groene velden zijn de beschikbare data.
    `;
export const AppointmentInformationCardDescription2 = 
    `
        Neem uw identititeitskaart mee op consultatie!
        Alle dokters werken met bankcontact.
    `;
export const AppointmentButtonLabel = 'Maak een afspraak';

//De Praktijk
export const ConsultationTitle = 'Consultatie';
export const ConsultationDescription = 
    `
    <h5>Richtlijnen bij het maken van een afspraak:</h5>
    <h5>
        <ul>
            <li>Doe dit liefst elektronisch.</li>
            <li>Gelieve voor twee (of meerdere) personen twee (of meerdere) afspraken te maken.</li>
            <li>Breng steeds een kleefbriefje van de mutualiteit en uw identiteitskaart mee.</li>
            <li>Kom op tijd. Indien u er niet geraakt: gelieve zo snel mogelijk te annuleren (via de website of telefonisch).</li>
        </ul>
    </h5>
    <h5>
        Indien alle consultaties op de website volzet zijn en u wenst toch een dringend medisch consult, dan kunt u altijd telefonisch terecht bij het telesecretariaat: <b>${TelNrVissenaken}</b>.
        <br>We zullen u dan in overleg zo snel mogelijk voorthelpen.
    </h5>    
    `;
export const HomeVisitTitle = 'Huisbezoek';
export const HomeVisitDescription = 
    `
    <h5>Huisbezoeken zijn niet altijd een gemakkelijke opgave. Daarom willen wij u vragen indien mogelijk steeds op raadpleging te komen.</h5>
    <h5>
        De omstandigheden voor onderzoek en behandeling zijn ideaal binnen de praktijk. Het laat ook toe om een aantal technische verstrekkingen zoals spirometrie, elektrocardiogram, kleine heelkunde, ... uit te voeren.
        Indien u echt niet in staat bent om naar de praktijk te komen kunt u een huisbezoek aanvragen.
    </h5>
    <h5>
        Voor niet-dringende huisbezoeken, aangevraagd na 14u, kunnen wij niet garanderen dat er dezelfde dag nog een van de dokters langskomt.
        U belt bij voorkeur voor 10 uur zodat we onze huisbezoeken in één beweging kunnen afleggen. U kunt ons bereiken via <b>${TelNrVissenaken}</b>.
    </h5>
    `;
export const OnDutyTitle = 'Wachtdienst';
export const OnDutyDescriptionWeek = "Van maandag t.e.m. donderdag is er een week-wachtdienst vanaf 19 uur. In geval van dringende medische hulp kunt u terecht op het centrale wachtdienst nummer:";
export const OnDutyDescriptionWeekend = "Vanaf vrijdagavond 19 uur tot maandagochtend 08 uur en op feestdagen kan u terecht bij de Wachtdienstpost:";

//Vertegenwoordigers
export const RepresentativesAppointmentButtonLabel = 'Maak een afspraak voor vertegenwoordigers';
export const RepresentativesDoctorDescription = `<h5>${DoctorName} ontvangt vertegenwoordigers op afspraak. U kunt een afspraak maken via de online agenda.</h5>`;
// export const RepresentativesHaioDescription = `<h5>${HaioName} ontvangt vertegenwoordigers op afspraak. U kunt een afspraak maken via de online agenda.`;
