import { Component, Input, OnInit } from '@angular/core';
import * as Constants  from '../../../assets/constants/Constants';

@Component({
  selector: 'app-contact-information',
  templateUrl: './contact-information.component.html',
  styleUrls: ['./contact-information.component.css']
})
export class ContactInformationComponent implements OnInit {
  C = Constants;

  @Input() office: string;
  @Input() telnr: string;
  @Input() address: string;
  @Input() doctors?: string;
  @Input() faxnr?: string;
  @Input() link?: string;

  constructor() { }

  ngOnInit(): void {
  }

}
