import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { MatGridListModule } from '@angular/material/grid-list';
import { FlexLayoutModule } from '@angular/flex-layout';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LinksComponent } from './links/links.component';
import { RepresentativesComponent } from './representatives/representatives.component';
import { MedicalOfficeComponent } from './medical-office/medical-office.component';
import { OnlineAppointmentComponent } from './online-appointment/online-appointment.component';
import { ContactComponent } from './contact/contact.component';
import { ContactInformationComponent } from './contact/contact-information/contact-information.component';
import { ContactMapComponent } from './contact/contact-map/contact-map.component';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    MatGridListModule,
    FlexLayoutModule
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    LinksComponent,
    RepresentativesComponent,
    MedicalOfficeComponent,
    OnlineAppointmentComponent,
    ContactComponent,
    ContactInformationComponent,
    ContactMapComponent
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
