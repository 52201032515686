import { Component, OnInit } from '@angular/core';
import * as Constants  from '../../assets/constants/Constants';

@Component({
  selector: 'app-medical-office',
  templateUrl: './medical-office.component.html',
  styleUrls: ['./medical-office.component.css']
})
export class MedicalOfficeComponent implements OnInit {
  C = Constants;

  constructor() { }

  ngOnInit(): void {
  }

}
