import { Component, OnInit } from '@angular/core';
import * as Constants  from '../../assets/constants/Constants';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  C = Constants;

  constructor() { }

  ngOnInit(): void {
  }

}
