import { Component, OnInit } from '@angular/core';
import * as Constants from '../../../assets/constants/Constants';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: '/home', title: 'Home',  icon: 'home', class: '' },
    { path: '/online-afspraak', title: 'Online Afspraak',  icon:'book_online', class: '' },
    { path: '/praktijk', title: 'De Praktijk',  icon:'medical_services', class: '' },
    { path: '/links', title: 'Links',  icon:'link', class: '' },
    { path: '/vertegenwoordigers', title: 'Vertegenwoordigers',  icon:'work', class: 'active-pro' }
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  C = Constants;

  constructor() { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };
}
